import Bildstock from "./Bildstock.JPG";
import CestaroGrab from "./CestaroGrab.JPG";
import Cestarogrillplatz from "./Cestarogrillplatz.JPG";
import Eichelbergturm from "./Eichelbergturm.JPG";
import EichelbergturmPlakette from "./EichelbergturmPlakette.JPG";
import EvangelischeKirche from "./EvangelischeKirche.JPG";
import Feuerwehrhaus from "./Feuerwehrhaus.JPG";
import Friedhof from "./Friedhof.JPG";
import KatholischeKirche from "./KatholischeKirche.JPG";
import Mannheim from "./Mannheim.JPG";
import Oberflockenbach from "./Oberflockenbach.JPG";
import Rathaus from "./Rathaus.JPG";
import Reinhardswiese from "./Reinhardswiese.JPG";
import Sanderpavillion from "./Sanderpavillion.JPG";
import Sauerpavillion from "./Sauerpavillion.JPG";
import Sendeturm from "./Sendeturm.JPG";
import Steinklingen from "./Steinklingen.JPG";
import Suppenschüssel from "./Suppenschüssel.JPG";
import SuppenschüsselPlakette from "./SuppenschüsselPlakette.JPG";
import Wachenburg from "./Wachenburg.JPG";
import Wuenschmichelbach from "./Wuenschmichelbach.jpeg";

type BannerbildType = [
    image: string,
    description: string
];
type BannerbilderType = BannerbildType[];

const Bannerbilder: BannerbilderType = [
    [Bildstock, "Rastplatz am Bildstock"],
    [CestaroGrab, "Grab von Adam Cestaro, letzter Bürgermeister von Oberflockenbach"],
    [Cestarogrillplatz, "Cestarogrillplatz"],
    [Eichelbergturm, "Mannheimer Hütte (Eichelbergturm)"],
    [EichelbergturmPlakette, "Plakette mit Erbauungsdatum, Mannheimer Hütte (Eichelbergturm)"],
    [EvangelischeKirche, "Evangelische Kirche"],
    [Feuerwehrhaus, "Feuerwehrgerätehaus"],
    [Friedhof, "Friedhof"],
    [KatholischeKirche, "Katholische Herz-Jesu-Kirche"],
    [Mannheim, "Blick nach Mannheim"],
    [Oberflockenbach, "Ortsteil Oberflockenbach"],
    [Rathaus, "Historisches Rathaus, erbaut 1901"],
    [Reinhardswiese, "Wohngebiet Reinhardswiese im Ortsteil Wünschmichelbach"],
    [Sanderpavillion, "Sanderpavillion"],
    [Sauerpavillion, "Sauerpavillion"],
    [Sendeturm, "Hardberg Sender, Siedelsbrunn"],
    [Steinklingen, "Ortsteil Steinklingen"],
    [Suppenschüssel, "Suppenschüssel"],
    [SuppenschüsselPlakette, "Plakette an der Suppenschüssel"],
    [Wachenburg, "Blick auf die Wachenburg"],
    [Wuenschmichelbach, "Ortsteil Wünschmichelbach"]
]

export { Bannerbilder, BannerbildType, BannerbilderType };