import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

import Images from './img/Index.js'

import NavigationDropdown from './NavigationDropdown';

import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';

const elemente = globalState.getState().Inhaltsverszeichnis.map((item, index) => {
    if (item[0] !== "Downloads" && item[0] !== "Impressum") {
        if (item[1].length > 0) {
            return (

                <NavigationDropdown
                    Kapitel={item[0]}
                    MenueEintraege={item[1]}
                    key={index}
                />
            );
        }
        else {
            return (
                <NavigationDropdown
                    Kapitel={item[0]}
                    key={uniqid()}
                />
            )
        }
    }
    return "";
});

const Navigation = () => {
    return (
        <AppBar
            style={{
                position: 'sticky',
                //top: '0',
                background: 'green',
                alignItems: 'center',
                //overflow: 'hidden',
                //padding: '0',
            }}
        >
            <Toolbar
                style={{
                    //display: "flex"
                }}
            >
                <Tooltip title="Wappen von Oberflockenbach">
                    <img
                        src={Images.WappenOrt}
                        alt="Wappen Ort"
                        style={{
                            height: '60px'
                        }}
                    />
                </Tooltip>
                {elemente}
                <Tooltip title="Eichenlaub, Wappen des OWK">
                    <img
                        src={Images.Eichenlaub}
                        alt="Eichenlaub"
                        style={{
                            height: '60px'
                        }}
                    />
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
};

export { Navigation };