import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { Absatz } from './Absatz.tsx';
import { Bild } from './Bild.tsx';
import { Bildergallerie } from './Bildergallerie.tsx';
import { Bilderkarten } from './Bilderkarten.tsx';
import { Facebook } from './Facebook.tsx';
import { HorizontaleLinie } from './HorizontaleLinie.tsx';
import Karte from './Karte';
import { Komoot } from './Komoot.tsx';
import { Liste } from "./Liste.tsx"
import { NummerierteListe } from './NummerierteListe.tsx';

import MediaQuery from "react-responsive";

import Funktionsbibliothek from "./../Funktionsbibliothek";

import uniqid from 'uniqid';

class Akkordeon extends React.Component {
    constructor(props) {
        super(props);

        this.fbib = new Funktionsbibliothek();

        this.state = {
            expanded: false,
            expandStates: {},
        }
        let tempExpandStates = {};
        this.props.sections.map((item, index) => {
            tempExpandStates[index] = false;
            return 0;
        });
        this.state.expandStates = tempExpandStates;
    }

    handleChange = (panel) => (event, isExpanded) => {
        //this.setState({expanded : isExpanded ? panel : false});
        let open = this.state.expandStates;
        if (open[panel] === false) {
            open[panel] = panel + 1;
        }
        else {
            open[panel] = false;
        }
        this.setState({ expandStates: open });
    }


    render() {
        return (
            <div>
                {
                    this.props.sections.map((item, index) => {
                        return (
                            <div key={index}>
                                <MediaQuery minWidth={this.fbib.WIDTH_SCREEN_PORTRAIT}>
                                    <Accordion
                                        //key={uniqid()}
                                        expanded={this.state.expandStates[index] === index + 1}
                                        onChange={this.handleChange(index)}
                                    >
                                        <AccordionSummary
                                            style={{
                                                background: "lightgrey",
                                                position: "sticky",
                                                top: 60
                                            }}
                                            expandIcon={<ExpandMoreIcon />}
                                            key={uniqid()}
                                        >
                                            <Typography>
                                                {item.heading}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Card
                                                style={{
                                                    border: "none",
                                                    boxShadow: "none"
                                                }}
                                            >
                                                <CardHeader
                                                    subheader={
                                                        <Typography
                                                            style={{
                                                                color: "grey"
                                                            }}
                                                        >
                                                            {item.publisher}<br />
                                                            veröffentlicht am: {item.date}
                                                        </Typography>
                                                    }
                                                />
                                                <CardContent>
                                                    {
                                                        item.content.map((item1, index1) => {
                                                            switch (item1.type) {
                                                                case "Absatz":
                                                                    return (<Absatz key={uniqid()} text={item1.text} crlf={item.crlf} />);

                                                                case "Akkordeon":
                                                                    return (<Akkordeon key={uniqid()} sections={item1.sections} />);

                                                                case "Bild":
                                                                    return (<Bild key={uniqid()} src={item1.src} desc={item1.desc} orientation={item1.orientation} />);

                                                                case "Bildergallerie":
                                                                    return (<Bildergallerie key={uniqid()} images={item1.images} orientation={item1.orientation} />)

                                                                case "Bilderkarten":
                                                                    return (<Bilderkarten key={uniqid()} cards={item1.cards} />);

                                                                case "Facebook":
                                                                    return (<Facebook key={uniqid()} src={item1.src} title={item1.title} href={item1.href} />);

                                                                case "HorizontaleLinie":
                                                                    return (<HorizontaleLinie key={uniqid()} />)

                                                                case "Karte":
                                                                    return (<Karte coordinates={item1.koordinaten} description={item1.beschreibung} zoom={item1.zoom} crlf={item.crlf} key={uniqid()} />);

                                                                case "Komoot":
                                                                    return (<Komoot key={uniqid()} src={item1.src} />);

                                                                case "Liste":
                                                                    return (<Liste key={uniqid()} listItems={item1.listItems} />);

                                                                case "NummerierteListe":
                                                                    return (<NummerierteListe key={uniqid()} listItems={item1.listItems} />);
                                                                default:
                                                                    return 0;
                                                            }

                                                        })
                                                    }
                                                </CardContent>
                                            </Card>
                                        </AccordionDetails>
                                        <Tooltip
                                            title="Bericht einklappen"
                                            style={{

                                            }}
                                        >
                                            <IconButton
                                                style={{
                                                    padding: "2px",
                                                    float: "right"
                                                }}
                                                onClick={this.handleChange(index)}
                                            >
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Accordion>
                                </MediaQuery>
                                <MediaQuery maxWidth={this.fbib.WIDTH_SCREEN_PORTRAIT}>
                                    <Accordion
                                        key={index}
                                        expanded={this.state.expandStates[index] === index + 1}
                                        onChange={this.handleChange(index)}
                                        TransitionProps={{ unmountOnExit: true }}
                                    >
                                        <AccordionSummary
                                            style={{
                                                background: "lightgrey",
                                                position: "sticky",
                                                top: 60,
                                                width: "100%"
                                            }}

                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography
                                                style={{
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                {item.heading}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Card
                                                style={{
                                                    border: "none",
                                                    boxShadow: "none"
                                                }}
                                            >
                                                <CardHeader
                                                    subheader={
                                                        <Typography
                                                            style={{
                                                                color: "grey"
                                                            }}
                                                        >
                                                            {item.publisher}<br />
                                                            veröffentlicht am: {item.date}
                                                        </Typography>
                                                    }
                                                />
                                                <CardContent>
                                                    {
                                                        item.content.map((item1, index1) => {
                                                            switch (item1.type) {
                                                                case "Absatz":
                                                                    return (<Absatz key={uniqid()} text={item1.text} crlf={item.crlf} />);

                                                                case "Akkordeon":
                                                                    return (<Akkordeon key={uniqid()} sections={item1.sections} />);

                                                                case "Bild":
                                                                    return (<Bild key={uniqid()} src={item1.src} desc={item1.desc} orientation={item1.orientation} />);

                                                                case "Bildergallerie":
                                                                    return (<Bildergallerie key={uniqid()} images={item1.images} orientation={item1.orientation} />)

                                                                case "Bilderkarten":
                                                                    return (<Bilderkarten key={uniqid()} cards={item1.cards} />);

                                                                case "Facebook":
                                                                    return (<Facebook key={uniqid()} src={item1.src} title={item1.title} href={item1.href} />);

                                                                case "HorizontaleLinie":
                                                                    return (<HorizontaleLinie key={uniqid()} />)

                                                                case "Karte":
                                                                    return (<Karte coordinates={item1.koordinaten} description={item1.beschreibung} zoom={item1.zoom} crlf={item.crlf} key={uniqid()} />);

                                                                case "Komoot":
                                                                    return (<Komoot key={uniqid()} src={item1.src} />);

                                                                case "Liste":
                                                                    return (<Liste key={uniqid()} listItems={item1.listItems} />);

                                                                case "NummerierteListe":
                                                                    return (<NummerierteListe key={uniqid()} listItems={item1.listItems} />);
                                                                default:
                                                                    return 0;
                                                            }

                                                        })
                                                    }
                                                </CardContent>
                                            </Card>
                                        </AccordionDetails>
                                        <Tooltip
                                            title="Bericht einklappen"
                                            style={{

                                            }}
                                        >
                                            <IconButton
                                                style={{
                                                    padding: "2px",
                                                    float: "right"
                                                }}
                                                onClick={this.handleChange(index)}
                                            >
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Accordion>
                                </MediaQuery>
                            </div>
                        );
                    })
                }
            </div>
        );
    };
}
export default Akkordeon;