import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import uniqid from 'uniqid'

import { globalState } from './globalState/globalState';

const eintraege = [
    ["Ansprechpartner", "/Startseite/Ansprechpartner"],
    ["Downloads", "/Downloads"],
    ["Wanderplan", "/Wanderungen/Wanderplan"],
    ["Wanderwoche", "/Wanderungen/Wanderwoche"],
    ["Wochenspiegel", "/Termine/Wochenspiegel"],
    ["Archiv", "/Archiv/Übersicht"]
];

const elemente = eintraege.map((item) => {
    return (
        <ListItem
            style={{
                padding: "0",
                textAlign: "left"
            }}
            key={uniqid()}
        >
            <ListItemIcon
                style={{
                    minWidth: "auto"
                }}
            >
                <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Link
                        component="button"
                        onClick={() => {
                            globalState.getState().setPageState(item[1]);
                        }}
                        underline="hover"
                        rel="noopener"
                        style={{
                            color: "black",
                            margin: "0",
                            padding: "0"
                        }}
                    >
                        <Typography>
                            {item[0]}
                        </Typography>
                    </Link>
                } />
        </ListItem>
    );
});

const Direktlinks = () => {
    return (
        <Card
            style={{
                border: "none",
                boxShadow: "none",
                background: "transparent"
            }}
        >
            <CardHeader
                title={
                    <Typography
                        component="div"
                        variant="h5"
                        textAlign="left"
                    >
                        Direktlinks:
                        <hr />
                    </Typography>
                }
                subheader={
                    <List
                        style={{
                            backgroundColor: "transparent",
                            marginTop: "-20px"
                        }}
                    >
                        {elemente}
                    </List>
                }
            />
        </Card>
    );
};

export { Direktlinks };