import * as React from 'react';

import { Banner } from "./Banner.tsx";

const Kopf = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center'
            }}
        >
            <Banner />
        </div>
    );
}
export { Kopf };