import * as React from 'react';
import Iframe from 'react-iframe';

import uniqid from 'uniqid';

interface KomootProps {
    crlf: boolean,
    src: string
}

const Komoot = (props: KomootProps) => {

    let lineBreak: JSX.Element;
    if (props.crlf) {
        lineBreak = (<br />);
    } else {
        lineBreak = (<div />)
    }

    return (
        <div>
            <Iframe
                title={uniqid()}
                url={props.src}
                width="100%"
                height="680"
                frameBorder={0}
                scrolling='no'
            />
            {lineBreak}
        </div>
    );
};
export { Komoot };


