import * as React from 'react';

import MediaQuery from "react-responsive";

import uniqid from 'uniqid';

import Funktionsbibliothek from "./../Funktionsbibliothek";
import { StyleSheet } from '../interfaces/Stylesheet';

const fbib = new Funktionsbibliothek();

interface FacebookProps {
    src: string,
    href: string
}

const Facebook = (props: FacebookProps) => {

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <div
                    style={styles.computerDiv}
                >
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1200px-Facebook_Logo_%282019%29.png"
                        key={uniqid()}
                        alt="Facebook Logo"
                        style={styles.computerFacebookLogo}
                    />
                    <button
                        style={styles.computerButton}
                        onClick={() => {
                            const url = props.src;
                            window.open(url, '_blank');
                        }}
                    >
                        Bilder zur Wanderung
                    </button>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <div
                    style={styles.phoneDiv}
                >
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1200px-Facebook_Logo_%282019%29.png"
                        key={uniqid()}
                        alt="Facebook Logo"
                        style={styles.phoneFacebookLogo}
                    />
                    <button
                        style={styles.phoneButton}
                        onClick={() => {
                            const url = props.src;
                            window.open(url, '_blank');
                        }}
                    >
                        Bilder zur Wanderung
                    </button>
                </div>
            </MediaQuery>
        </div>
    );
};

const styles: StyleSheet = {
    computerDiv: {
        display: "flex",
        justifyContent: "left",
        alignContent: "baseline",
        marginLeft: "10%"
    },
    computerFacebookLogo: {
        width: "auto",
        height: "40px"
    },
    computerButton: {
        height: "40px",
        marginLeft: "10px",
        width: "83%"
    },
    phoneDiv: {
        display: "flex",
        justifyContent: "left",
        alignContent: "center"
    },
    phoneFacebookLogo: {
        width: "auto",
        height: "40px"
    },
    phoneButton: {
        height: "40px",
        marginLeft: "10px",
        width: "100%"
    }
}

export { Facebook };