import * as React from 'react';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const FussLinks = () => {
    return (
        <List
            style={{
                backgroundColor: "transparent",
                padding: "10px"
            }}
        >
            <ListItem
                style={{
                    padding: "0"
                }}
            >
                <ListItemText
                    primary="Verwandte Seiten:"
                />
            </ListItem>
            <ListItem
                style={{
                    padding: "0"
                }}
            >
                <ListItemIcon
                    style={{
                        minWidth: "auto"
                    }}
                >
                    <KeyboardArrowRightIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Link
                            href="https://www.weinheim.de/,Lde/575505.html"
                            underline="hover"
                            target="_blank"
                            rel="noopener"
                            style={{
                                color: "black",
                                margin: "0",
                                padding: "0"
                            }}
                        >
                            Stadt Weinheim
                        </Link>
                    } />
            </ListItem>
            <ListItem
                style={{
                    padding: "0"
                }}
            >
                <ListItemIcon
                    style={{
                        minWidth: "auto"
                    }}
                >
                    <KeyboardArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={
                    <Link
                        href="https://www.odenwaldklub.de/"
                        underline="hover"
                        target="_blank"
                        rel="noopener"
                        style={{
                            color: "black"
                        }}
                    >
                        OWK-Zentrale Fürstenlager
                    </Link>
                } />
            </ListItem>
            <ListItem
                style={{
                    padding: "0"
                }}
            >
                <ListItemIcon
                    style={{
                        minWidth: "auto"
                    }}
                >
                    <KeyboardArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={
                    <Link
                        href="https://www.weinheim.de/startseite/buergerservice/verwaltungsstelle+oberflockenbach.html"
                        underline="hover"
                        target="_blank"
                        rel="noopener"
                        style={{
                            color: "black"
                        }}
                    >
                        Verwaltungsstelle Oberflockenbach
                    </Link>
                } />
            </ListItem>
        </List>
    );
};

export { FussLinks };