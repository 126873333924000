import * as React from 'react';

import { Direktlinks } from './Direktlinks.tsx';
import { Kapitelinhalt } from './Kapitelinhalt.tsx';
import { NaechsteWanderung } from './NaechsteWanderung.tsx';
import { Wanderplan } from "./Wanderplan.tsx";
import { Wanderwetter } from './Wanderwetter.tsx';

const HauptteilLinks = () => {
    return (
        <div>
            <NaechsteWanderung />
            <Kapitelinhalt />
            <Direktlinks />
            <Wanderplan />
            <Wanderwetter />
        </div>
    );
}
export { HauptteilLinks };