import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Typography from '@mui/material/Typography';


const Wanderplan = () => {
    return (
        <Card
            style={{
                border: "none",
                boxShadow: "none",
                background: "transparent"
            }}
        >
            <CardHeader
                title={
                    <Typography
                        component="div"
                        variant="h5"
                        textAlign="left"
                    >
                        Wanderplan:
                        <hr />
                    </Typography>
                }
                subheader={
                    <iframe
                        src="https://calendar.google.com/calendar/embed?height=250&amp;wkst=2&amp;bgcolor=%230B8043&amp;ctz=Europe%2FBerlin&amp;src=ZG1hcjlsc3Y4cDNqZW9oajEwOHE1Nm42MWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%233F51B5&amp;showTitle=0&amp;showNav=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;mode=AGENDA"
                        title="Wanderplan"
                        width="100%"
                        height="250"
                        style={{
                            borderRadius: "5px",
                            borderColor: "gainsboro",
                            borderWidth: "1px",
                            float: "right"
                        }}
                    />
                }
            />
        </Card>
    );
}

export { Wanderplan };