import * as React from 'react';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { globalState } from './globalState/globalState';

import uniqid from 'uniqid'

const eintraege = [
    ["Downloads", "/Downloads"],
    ["Impressum", "/Impressum"],
    ["Archiv", "/Archiv/Übersicht"],
    ["Sitemap", "/sitemap.xml"]
];

const elemente = eintraege.map((item) => {
    if (item[0] === "Sitemap") {
        return (
            <ListItem
                key={uniqid()}
                style={{
                    padding: "0",
                    textAlign: "right"
                }}
            >
                <ListItemText
                    style={{

                    }}
                    primary={
                        <Link
                            href={item[1]}
                            underline="hover"
                            rel="noopener"
                            target="_blank"
                            style={{
                                color: "black",
                                margin: "0",
                                padding: "0"
                            }}
                        >
                            <Typography>{item[0]}</Typography>
                        </Link>
                    }
                />
                <ListItemIcon
                    style={{
                        minWidth: "auto"
                    }}
                >
                    <KeyboardArrowLeftIcon />
                </ListItemIcon>
            </ListItem>
        );
    }
    else {
        return (
            <ListItem
                key={uniqid()}
                style={{
                    padding: "0",
                    textAlign: "right"
                }}
            >
                <ListItemText
                    style={{

                    }}
                    primary={
                        <Link
                            component="button"
                            onClick={() => {
                                globalState.getState().setPageState(item[1]);
                            }}
                            underline="hover"
                            rel="noopener"
                            style={{
                                color: "black",
                                margin: "0",
                                padding: "0"
                            }}
                        >
                            <Typography>{item[0]}</Typography>
                        </Link>
                    }
                />
                <ListItemIcon
                    style={{
                        minWidth: "auto"
                    }}
                >
                    <KeyboardArrowLeftIcon />
                </ListItemIcon>
            </ListItem>
        );
    }
});

const FussRechts = () => {
    return (
        <List
            style={{
                backgroundColor: "transparent",
                paddingRight: "10px",

            }}
        >
            <ListItem
                style={{
                    padding: "0",
                    textAlign: "right"
                }}
            >
                <ListItemText
                    style={{

                    }}
                    primary="Weitere Links:"
                />
            </ListItem>
            {elemente}
        </List>
    );
};

export { FussRechts };