import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';

import MediaQuery from "react-responsive";

import uniqid from 'uniqid';

import { StyleSheet } from '../interfaces/Stylesheet';
import Funktionsbibliothek from "./../Funktionsbibliothek";

const fbib = new Funktionsbibliothek();


interface BildProps {
    src: string,
    desc: string,
    orientation: string | undefined
}

const Bild = (props: BildProps) => {

    if (props.orientation === undefined || props.orientation === "quer") {
        styles = {
            pictureStyle: {
                height: "auto",
                width: "80%",
                borderRadius: 10
            },
            pictureStyleSmartphone: {
                height: "auto",
                width: "100%",
                borderRadius: 10
            }
        }
    } else {
        styles = {
            pictureStyle: {
                height: "auto",
                width: "385px",
                borderRadius: 10
            },
            pictureStyleSmartphone: {
                height: "auto",
                width: "100%",
                borderRadius: 10
            }
        }
    }

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <div
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }}
                >
                    <Tooltip title={props.desc}>
                        <img
                            style={styles.pictureStyle}
                            src={props.src}
                            key={uniqid()}
                            alt={props.desc}
                        />
                    </Tooltip>
                    <br /><br />
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <div
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }}
                >
                    <Tooltip title={props.desc}>
                        <img
                            style={styles.pictureStyleSmartphone}
                            src={props.src}
                            key={uniqid()}
                            alt={props.desc}
                            width={10}
                        />
                    </Tooltip>
                    <br /><br />
                </div>
            </MediaQuery>
        </div>
    );
};

let styles: StyleSheet = {
    pictureStyle: {},
    pictureStyleSmartphone: {}
}

export { Bild };



