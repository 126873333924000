import * as React from 'react';

interface MastodonProps {
    width: string
}

const Mastodon = (props: MastodonProps) => {
    return (
        <iframe
            title="Mastodon"
            sandbox="allow-top-navigation allow-scripts allow-popups allow-popups-to-escape-sandbox"
            width={props.width}
            height="300"
            style={{
                borderRadius: "5px",
                borderColor: "gainsboro",
                borderWidth: "1px",
                float: "right"
            }}
            src="https://mastofeed.com/apiv2/feed?userurl=https%3A%2F%2Frheinneckar.social%2Fusers%2FOWKOfl&theme=light&size=100&header=false&replies=false&boosts=false" >
        </iframe>
    );
}

export { Mastodon };