import * as React from 'react';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';

import HoverMenu from 'material-ui-popup-state/HoverMenu'
import PopupState from 'material-ui-popup-state'

import { createBrowserHistory } from "history";

import Funktionsbibliothek from "./Funktionsbibliothek";
import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';

class NavigationDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false,
            display: 'none',
            caption: (<div style={{ display: 'flex' }}>{this.props.Kapitel} <ArrowDropDownIcon /></div>),
        };

        this.mitMenu = true;

        if (this.props.MenueEintraege !== undefined) {
            this.menuEintraege = this.props.MenueEintraege;
            this.menuEintraege.forEach(element => {
                element.trim();
            });
        }
        else {

            this.mitMenu = false;
        }

        this.mitPageState = globalState.getState().pageState !== undefined ? true : false;

        this.fbib = new Funktionsbibliothek();

        this.history = createBrowserHistory();
        this.handleLinkClicked = this.handleLinkClicked.bind(this);
    }

    componentDidMount() {
        try {
            this.history.listen((location, action) => {
                switch (action) {
                    case "PUSH":
                        this.handleLinkClicked();
                        break;
                    default:
                        break;
                }
            });

            if (this.mitPageState) {
                //let pageStateKapitel = window.location.pathname.split('/')[1] ; //Index 0 ist leerer String

                //Put entries of "Startseite" if chapter is empty
                if (this.props.Kapitel === "Startseite") {
                    globalState.getState().setMenueEintraege(this.props.MenueEintraege);
                }

                if (this.props.Kapitel === globalState.getState().Kapitel) {
                    globalState.getState().setMenueEintraege(this.props.MenueEintraege);
                }
                else {
                    switch (this.centralState.Kapitel) {
                        case "Downloads":
                            globalState.getState().setMenueEintraege((this.centralState.Inhaltsverszeichnis[6])[1]);
                            break;
                        case "Archiv":
                            globalState.getState().setMenueEintraege((this.centralState.Inhaltsverszeichnis[7])[1]);
                            break;
                        case "Impressum":
                            globalState.getState().setMenueEintraege((this.centralState.Inhaltsverszeichnis[5])[1]);
                            break;
                        default:
                            break;
                    }
                }

            }
        }
        catch
        {

        }
    }

    handleLinkClicked = (event) => {
        let pageStateKapitel = window.location.pathname.split('/')[1]; //Index 0 ist leerer String
        if (globalState.getState().Kapitel === pageStateKapitel) {
            globalState.getState().setKapitel(this.props.Kapitel);
            globalState.getState().getMenueEintraege(this.props.MenueEintraege);
        }

    }

    handleClick = unterseite => event => {
        event.stopPropagation();
        if (window.visualViewport.width >= this.fbib.WIDTH_SCREEN_LANDSCAPE) {
            if (window.scrollY > 420) {
                window.scrollTo(0, 420);
            }
        }
        else {
            if (window.scrollY > 320) {
                window.scrollTo(0, 320);
            }
        }

        if (this.state.anchorEl !== event.currentTarget) {
            this.setState({
                open: false,
                anchorEl: null,
                caption: (<div style={{ display: 'flex' }}>{this.props.Kapitel} <ArrowDropDownIcon /></div>)
            });
            if (this.mitPageState) {
                globalState.getState().setPageState("/" + this.props.Kapitel + "/" + unterseite.replace(" ", ""));
            }
        }
    }

    handleOpen = (event) => {
        event.stopPropagation();
        if (this.state.anchorEl !== event.currentTarget) {
            this.setState({
                open: true,
                anchorEl: event.currentTarget,
                caption: (<div style={{ display: 'flex' }}>{this.props.Kapitel} <ArrowDropUpIcon /></div>)
            });
        }
    }

    handleClose = (event) => {
        this.setState({
            open: false,
            anchorEl: null,
            caption: (<div style={{ display: 'flex' }}>{this.props.Kapitel} <ArrowDropDownIcon /></div>)
        });
    }

    render() {
        let renderMenu;
        if (this.mitMenu) {
            renderMenu = (
                <PopupState variant="popover" popupId="demoMenu">
                    {(popupState) => (
                        <React.Fragment>
                            <div
                                onMouseEnter={this.handleOpen}
                                onMouseLeave={this.handleClose}
                            >
                                <Button
                                    style={{
                                        color: 'white',
                                        height: '60px',
                                    }}
                                    color="inherit"
                                    size='large'
                                    onClick={this.handleOpen}

                                //{...bindHover(popupState)}

                                >
                                    {this.state.caption}
                                </Button>
                                <HoverMenu
                                    style={{
                                        marginTop: "0px",
                                        getContentAnchorEl: "null"
                                    }}
                                    //{...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    disableScrollLock={true}
                                    open={this.state.open}
                                    anchorEl={this.state.anchorEl}
                                    disableRestoreFocus={true}
                                    transitionDuration={70}
                                    disableAutoFocusItem={true}
                                >
                                    {this.menuEintraege.map((item, index) => {
                                        return <MenuItem onClick={this.handleClick(item)} key={uniqid()}>{item}</MenuItem>
                                    })}
                                </HoverMenu>
                            </div>
                        </React.Fragment>
                    )}
                </PopupState>
            );
        }
        else {
            renderMenu =
                (
                    <div
                        onMouseOver={this.handleClick}
                        onMouseLeave={this.handleClose}
                    >
                        <Tooltip title="Leider noch leer">
                            <Button
                                style={{
                                    color: 'white',
                                    height: '60px'
                                }}
                                size='large'
                                color="inherit"
                            >
                                {this.state.caption}
                            </Button>
                        </Tooltip>
                    </div>
                );
        }
        return (
            renderMenu
        );
    }
}
export default NavigationDropdown;