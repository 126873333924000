import * as React from 'react';

import Typography from '@mui/material/Typography';

const Titel = () => {
    return (
        <Typography
            component="div"
            variant="h4"
            style={{
                textAlign: "center"
            }}
        >
            Odenwaldklub Ortsgruppe Oberflockenbach
        </Typography>
    );
}
export { Titel };