import Eichenlaub from "./Eichenlaub.png";
import KomootIcon from "./komoot-icon.png"
import MastodonIcon from "./Mastodon.png"
import PlaystoreIcon from "./playstore-icon.png"
import WappenOrt from "./Wappen_Ort.png";

const Images = {
    Eichenlaub,
    KomootIcon,
    MastodonIcon,
    PlaystoreIcon,
    WappenOrt
}

export default Images;