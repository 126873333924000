import * as React from 'react';

import CookieConsent from "react-cookie-consent";
import { Typography } from '@mui/material';

const CookieMeldung = () => {
  return (
    <CookieConsent
      style={{
        zIndex: "9999"
      }}
      buttonText="Ich habe verstanden"
      buttonStyle={{
        background: "green",
        color: "white",
        fontSize: "14pt",
        padding: "15px"
      }}
      location="top"
      cookieName="OWKOberflockenbach"
    >
      <Typography>
        Sehr geehrte Damen und Herren,<br></br><br></br>
        auch wir verwenden Cookies bzw. Software von Drittanbietern, die solche verwenden.
        Ein Cookie (deutsch Keks) ist ein Texteintrag im Speicher Ihres Browsers.<br></br><br></br>
        Diese Seite macht keine Werbung, kommt jedoch ohne Cookies nicht mehr aus.
        Manche der verwendeten, jeodch sehr bekannten Plug-Ins zeichnen in einem nicht transparenten Umfang die Aktivität der Seitennutzer auf.<br></br><br></br>
        Wenn Sie damit einverstanden sind, bestätigen Sie bitte diesen Dialog mit der nebenstehenden Schaltfläche.<br></br><br></br>
        Ausführlichere Informationen finden Sie im Impressum.
      </Typography>
    </CookieConsent>

  );

}

export { CookieMeldung };
