import * as React from 'react';

import Typography from '@mui/material/Typography';

import parse from 'html-react-parser';

import MediaQuery from "react-responsive";

import { StyleSheet } from '../interfaces/Stylesheet';

import Funktionsbibliothek from "./../Funktionsbibliothek";

import uniqid from 'uniqid';

interface Absatzprops {
    text: string,
    crlf: boolean
}

const fbib = new Funktionsbibliothek();

const Absatz = (props: Absatzprops) => {
    let lineBreak;
    if (props.crlf) {
        lineBreak = (<br />);
    }

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <Typography key={uniqid()}
                    style={styles.computer}
                >
                    {parse(props.text)}
                    {lineBreak}
                    {lineBreak}
                </Typography>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <Typography
                    key={uniqid()}
                    style={styles.portrait}
                >
                    {parse(props.text)}
                    {lineBreak}
                    {lineBreak}
                </Typography>
            </MediaQuery>
        </div>
    );
}

const styles: StyleSheet = {
    computer: {
        maxWidth: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "13pt",
        lineHeight: "1.5",
        textAlign: "justify",
        hyphens: "auto"
    },
    phone: {
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "13pt",
        lineHeight: "1.5",
        textAlign: "justify",
        hyphens: "auto"
    }
};

export { Absatz };