import { Typography } from '@mui/material';
import * as React from 'react';

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MediaQuery from "react-responsive";


import Funktionsbibliothek from "./../Funktionsbibliothek";

class Karte extends React.Component {
    constructor(props) {
        super(props);

        this.fbib = new Funktionsbibliothek();
    }

    render() {
        let lineBreak;
        if (this.props.crlf) {
            lineBreak = (<br />);
        }

        return (
            <div
                style={{
                    width: "100%"
                }}
            >
                <MediaQuery minWidth={this.fbib.WIDTH_SCREEN_PORTRAIT}>
                    <MapContainer center={this.props.coordinates} zoom={this.props.zoom} scrollWheelZoom={false}
                        style={{
                            height: 500,
                            width: "80%",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        className="map"
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={this.props.coordinates} >
                            <Tooltip
                                permanent
                                direction="top"
                                offset={[-16, -15]}
                            >
                                <Typography>{this.props.description}</Typography>
                            </Tooltip>
                        </Marker>
                    </MapContainer>
                </MediaQuery>
                <MediaQuery maxWidth={this.fbib.WIDTH_SCREEN_PORTRAIT}>
                    <MapContainer center={this.props.coordinates} zoom={this.props.zoom} scrollWheelZoom={false} tap={false} dragging={false}
                        style={{
                            height: 500,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                        className="map"
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={this.props.coordinates} >
                            <Tooltip
                                permanent
                                direction="top"
                                offset={[-16, -15]}
                            >
                                <Typography>{this.props.description}</Typography>
                            </Tooltip>
                        </Marker>
                    </MapContainer>
                </MediaQuery>
                {lineBreak}
                {lineBreak}

            </div>
        );
    }

}

export default Karte;