import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import NavigationDropdown from './NavigationDropdown';
import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';

class NavigationNullHeight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageState: this.props.pageState !== undefined ? this.props.pageState : ""
        }
    }

    render() {
        let elemente = globalState.getState().Inhaltsverszeichnis.map((item, index) => {
            if (item[0] !== "Downloads" && item[0] !== "Impressum") {
                if (item[1].length > 0) {
                    return (
                        <NavigationDropdown
                            Kapitel={item[0]}
                            MenueEintraege={item[1]}
                            key={index}
                        />
                    );
                }
                else {
                    return (
                        <NavigationDropdown
                            Kapitel={item[0]}
                            key={uniqid()}
                        />
                    )
                }
            }
            return 0;
        });

        return (
            <AppBar
                style={{
                    height: "0",
                    width: "0",
                    maxHeight: "0",
                    maxWidth: "0"
                }}
            >
                <Toolbar
                    style={{
                        height: "0",
                        width: "0",
                        maxHeight: "0",
                        maxWidth: "0"
                    }}
                >
                    {
                        elemente
                    }
                </Toolbar>
            </AppBar>
        );
    };
}
export default NavigationNullHeight;