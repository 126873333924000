import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import Images from './img/Index.js'

const FussMitte = () => {
    return (
        <List
            style={{
                backgroundColor: "transparent"
            }}
        >
            <ListItem
                style={{
                    padding: "0",
                    textAlign: "center"
                }}
            >
                <ListItemText
                    primary={<Typography>Social Media:</Typography>}
                />
            </ListItem>
            <ListItem
                style={{
                    padding: "0",
                    textAlign: "right"
                }}
            >
                <ListItemText
                    primary={
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <Tooltip title="Facebook">
                                <IconButton
                                    style={{
                                        padding: "0px",
                                        marginTop: "1px",
                                        marginBottom: "1px"
                                    }}
                                    onClick={() => {
                                        let url = "https://www.facebook.com/OWKOfl";
                                        window.open(url, '_blank');
                                    }
                                    }
                                >
                                    <FacebookIcon
                                        style={{
                                            color: "black",
                                            width: "50px",
                                            height: "auto"
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Komoot">
                                <IconButton
                                    style={{
                                        padding: "2px",
                                    }}
                                    onClick={() => {
                                        let url = "https://www.komoot.de/user/1654017259531";
                                        window.open(url, '_blank');
                                    }
                                    }
                                >
                                    <img
                                        src={Images.KomootIcon}
                                        style={{
                                            width: "40px",
                                            height: "auto"
                                        }}
                                        alt=""
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Google Playstore">
                                <IconButton
                                    style={{
                                        padding: "2px",
                                    }}
                                    onClick={() => {
                                        let url = "https://play.google.com/store/apps/details?id=com.owk_wanderapp.app";
                                        window.open(url, '_blank');
                                    }
                                    }
                                >
                                    <img
                                        src={Images.PlaystoreIcon}
                                        style={{
                                            width: "40px",
                                            height: "auto"
                                        }}
                                        alt=""
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Mastodon">
                                <IconButton
                                    style={{
                                        padding: "2px",
                                    }}
                                    onClick={() => {
                                        let url = "https://rheinneckar.social/@OWKOfl";
                                        window.open(url, '_blank');
                                    }
                                    }
                                >
                                    <img
                                        src={Images.MastodonIcon}
                                        style={{
                                            width: "40px",
                                            height: "auto"
                                        }}
                                        alt=""
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Twitter">
                                <IconButton
                                    style={{
                                        padding: "2px",
                                    }}
                                    onClick={() => {
                                        let url = "https://twitter.com/OWKOfl";
                                        window.open(url, '_blank');
                                    }
                                    }
                                >
                                    <TwitterIcon
                                        style={{
                                            color: "black",
                                            width: "40px",
                                            height: "auto"
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                />
            </ListItem>
        </List>
    );
};

export { FussMitte };