class Funktionsbibliothek {
    WIDTH_SCREEN_LANDSCAPE = 1100;
    WIDTH_SCREEN_PORTRAIT = 900;
    MAX_WIDTH_SCREEN = 1980;

    constructor() {
        this.Sonderzeichenreferenz = class {
            static URLzuUmlaut(input) {
                return input.replace("%C3%84", "Ä")
                    .replace("%C3%84", "Ö")
                    .replace("%C3%9C", "Ü")
                    .replace("%C3%9F", "ß")
                    .replace("%C3%A4", "ä")
                    .replace("%C3%B6", "ö")
                    .replace("%C3%BC", "ü");
            }
        };
    }

    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

}

export default Funktionsbibliothek;