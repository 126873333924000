import * as React from 'react';

class Wetter extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");

        script.src = "//cs3.wettercomassets.com/woys/5/js/w.js";
        script.async = true;
        script.onload = () => {
            window._wcomWidget({ id: 'wcom-231d5f4ad4c4160d258d4ebe01f2ad29', location: 'DE0011348007', format: '300x250', type: 'spaces' });
        }
        document.body.appendChild(script);
    }

    render() {
        return (
            <div
                id="wcom-231d5f4ad4c4160d258d4ebe01f2ad29"
                className="wcom-default w300x250"
                style={{
                    border: "1px solid #CCC",
                    backgroundColor: "#FCFCFC",
                    borderRadius: "5px",
                    width: "100%",
                    float: "left"
                }}
            >
                <div className="wcom-city">
                    <a
                        style={{
                            color: "#000"
                        }}
                        href="https://www.wetter.com/deutschland/weinheim/oberflockenbach/DE0011348007.html"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Wetter Berlin"
                        title="Wetter Oberflockenbach"
                        width="100%"
                    >
                        Wetter Oberflockenbach
                    </a>
                </div>
                <div
                    id="wcom-231d5f4ad4c4160d258d4ebe01f2ad29-weather"
                />
            </div>
        );
    };
}
export default Wetter;