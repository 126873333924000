import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

import Images from './img/Index.js'

import Wetter from "./Wetter";
import { Typography } from '@mui/material';

import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';
import { Mastodon } from './Mastodon.tsx';

class NavigationDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageState: globalState.getState().pageState !== undefined ? this.props.pageState : "",
            open: false
        }
    }

    handleClick = (kapitel, unterseite) => (event) => {
        if (kapitel !== "" && unterseite !== "") {
            if (kapitel === "Downloads" || kapitel === "Impressum") {
                globalState.getState().setPageState("/" + kapitel);
            }
            else {
                globalState.getState().setPageState("/" + kapitel + "/" + unterseite.replace(" ", ""));
            }

        }
        this.setState({ open: !this.state.open })
    }

    render() {
        let elemente = globalState.getState().Inhaltsverszeichnis.map((item, index) => {
            return (
                <div
                    key={uniqid()}
                >
                    <ListItem>
                        <Typography>{item[0]}:</Typography>
                    </ListItem>
                    {
                        item[1].map((item1, index1) => {
                            return (
                                <ListItem
                                    key={uniqid()}
                                >
                                    <Button
                                        style={{
                                            width: "100%",
                                            color: "#1769aa",
                                            minHeight: "35px"
                                        }}
                                        onClick={this.handleClick(item[0], item1)}
                                    >
                                        {item1}
                                    </Button>
                                </ListItem>
                            );
                        })
                    }
                    <Divider />
                </div>
            );
        });

        return (
            <AppBar
                style={{
                    position: 'sticky',
                    top: '0',
                    background: 'green',
                    alignItems: 'center',
                    overflow: 'hidden',
                    padding: '0',

                }}
            >
                <Toolbar
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between"
                    }}
                >
                    <Tooltip title="Wappen von Oberflockenbach">
                        <img
                            src={Images.WappenOrt}
                            alt="Wappen Ort"
                            style={{
                                height: '60px'
                            }}
                        />
                    </Tooltip>
                    <Button
                        style={{
                            color: 'white',
                            height: '60px',
                            flexGrow: "1"
                        }}
                        color="inherit"
                        size='large'
                        onClick={this.handleClick("", "")}
                    >
                        Menü
                    </Button>
                    <SwipeableDrawer
                        anchor='left'
                        open={this.state.open}
                        onOpen={() => { }}
                        onClose={this.handleClick("", "")}
                    >
                        <List>
                            <ListItem>
                                <Typography>Nächste Wanderung:</Typography>
                            </ListItem>
                            <ListItem
                                style={{
                                    minWidth: "340px",
                                    minHeight: "270px"
                                }}
                            >
                                <Mastodon width={"300px"} />
                            </ListItem>
                            <Divider />
                            {elemente}
                            <ListItem>
                                <Typography>Wanderwetter:</Typography>
                            </ListItem>
                            <ListItem>
                                <Wetter />
                            </ListItem>
                        </List>
                    </SwipeableDrawer>
                    <Tooltip title="Eichenlaub, Wappen des OWK">
                        <img
                            src={Images.Eichenlaub}
                            alt="Eichenlaub"
                            style={{
                                height: '60px'
                            }}
                        />
                    </Tooltip>
                </Toolbar>
            </AppBar>
        );
    };
}
export default NavigationDrawer;