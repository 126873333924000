import * as React from 'react';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

import MediaQuery from "react-responsive";

import { FussLinks } from "./FussLinks.tsx";
import { FussMitte } from './FussMitte.tsx';
import { FussRechts } from "./FussRechts.tsx";

import Funktionsbibliothek from "./Funktionsbibliothek";
import { Copyright } from './Copyright.tsx';

const fbib = new Funktionsbibliothek();

const Fuss = () => {
    return (
        <div
            style={{
                background: "lightgrey",
                paddingTop: "20px"
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    marginTop: "auto",
                }}
            >
                <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                    <Grid container wrap="nowrap" maxWidth={fbib.MAX_WIDTH_SCREEN}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        <Grid item xs={2}></Grid>
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-end">
                                <FussLinks />
                            </Box>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs>
                            <FussMitte />
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-start">
                                <FussRechts />
                            </Box>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </MediaQuery>
                <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                    <div />
                    <FussMitte />
                    <div />
                </MediaQuery>
            </div>
            <Copyright />
        </div>
    );
};
export { Fuss };