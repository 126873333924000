import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';

import { Content } from "./contentComponents/Content.tsx";

import { globalState } from './globalState/globalState';

interface HauptteilMitteProps {
    minHeight: string
}

const HauptteilMitte = (props: HauptteilMitteProps) => {
    return (
        <Card
            variant="outlined"
            style={{
                minHeight: props.minHeight,
                height: "100%",
                width: "100%",
                flexShrink: 0,
                background: "#FEFEFA"
            }}
        >
            <CardHeader
                title={
                    <Typography
                        component="div"
                        variant="h4"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        {globalState.getState().data.title}
                        <hr />
                    </Typography>
                }
                subheader={
                    <Typography
                        style={{
                            color: "grey"
                        }}
                    >
                        {globalState.getState().data.publisher}<br />
                        veröffentlicht am: {globalState.getState().data.date}
                    </Typography>
                }
            />
            <CardContent>
                <Content renderContent={globalState.getState().data.renderContent} />
            </CardContent>
        </Card>
    );
}
export { HauptteilMitte };