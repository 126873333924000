import * as React from 'react';

import Carousel from 'react-material-ui-carousel';

import { Bild } from "./Bild.tsx";

import uniqid from 'uniqid';

interface BildergallerieProps {
    crlf: boolean,
    images: any
}

const Bildergallerie = (props: BildergallerieProps) => {

    let lineBreak;
    if (props.crlf) {
        lineBreak = (<br />);
    }

    return (
        <Carousel
            key={uniqid()}
            disableScrollLock={true}
            //navButtonsAlwaysInvisible={true}
            interval={6000}
        >
            {
                props.images.map((item, index) => {
                    return (
                        <Bild key={uniqid()} src={item.src} desc={item.desc} orientation={item.orientation} />
                    );
                })
            }
            {lineBreak}
        </Carousel>
    );
}

export { Bildergallerie };