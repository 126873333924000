import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Typography from '@mui/material/Typography';

import Wetter from './Wetter';

const Wanderwetter = () => {
    return (
        <Card
            style={{
                border: "none",
                boxShadow: "none",
                background: "transparent"
            }}
        >
            <CardHeader
                title={
                    <Typography
                        component="div"
                        variant="h5"
                        textAlign="left"
                    >
                        Wanderwetter:
                        <hr />
                    </Typography>
                }
                subheader={
                    <Wetter />
                }
            />
        </Card>
    );
}
export { Wanderwetter };