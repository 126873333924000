import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';

import parse from 'html-react-parser';

import uniqid from 'uniqid';

import { StyleSheet } from '../interfaces/Stylesheet';

interface Cards {
    name: string,
    position: string,
    image: string,
    content: string
}

interface BilderkartenProps {
    crlf: boolean,
    cards: Cards[]
}

const Bilderkarten = (props: BilderkartenProps) => {
    let lineBreak: JSX.Element;
    if (props.crlf) {
        lineBreak = (<br />);
    } else {
        lineBreak = (<div />)
    }

    return (
        <div>
            <div
                style={styles.container}
                key={uniqid()}
            >
                {
                    props.cards.map((value, index) => {
                        return (
                            <Card key={uniqid()}>
                                <CardHeader
                                    title={value.name}
                                    subheader={value.position}
                                    style={{
                                        minWidth: "300px"
                                    }}
                                />
                                <CardMedia
                                    component="img"
                                    image={value.image}
                                    style={styles.cards}
                                />
                                <CardContent>
                                    <Typography>
                                        {parse(value.content)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        );
                    })
                }
            </div>
            {lineBreak}
        </div>
    );
}

const styles: StyleSheet = {
    container: {
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
        justifyContent: "center"
    },
    cards: {
        width: "auto",
        height: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 10
    }
};

export { Bilderkarten };